
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { useReCaptcha } from 'vue-recaptcha-v3';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';
import { useCreateContact } from '@/hooks/contact/use-contact';

export default defineComponent({
  components: { AppInput, AppButton, ChevronRightIcon, DisplayError },
  setup() {
    const captchaLoading = ref(true);
    const captcha = useReCaptcha();
    const { showToast } = useToast();
    const { user } = useUser();
    const { loading, errors, createContact } = useCreateContact();
    const contactData = ref({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      user: user.value?.id ?? undefined,
      captchaToken: '',
    });

    onMounted(async () => {
      try {
        captchaLoading.value = true;

        await captcha?.recaptchaLoaded();
        captcha?.instance.value?.showBadge();
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }
    });

    onBeforeUnmount(() => {
      captcha?.instance.value?.hideBadge();
    });

    const onSubmit = async (e: Event) => {
      captchaLoading.value = true;
      const token = await captcha?.executeRecaptcha('submit');

      if (!token) {
        captchaLoading.value = false;
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
        return;
      }

      contactData.value.captchaToken = token;

      if (!contactData.value.user) {
        delete contactData.value.user;
      }

      await createContact(contactData.value);

      captchaLoading.value = false;

      if (!errors.value.length) {
        showToast({ message: `Votre message a été envoyé! Nous vous répondrons bientôt` });
        (e.target as HTMLFormElement).reset();
      }
    };

    return {
      onSubmit,
      contactData,
      loading,
      errors,
      captchaLoading,
    };
  },
});
