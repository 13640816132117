<template>
  <div
    class="
      h-full
      flex-1 flex
      items-center
      justify-center
      shadow-lg
      rounded-xl
      bg-gray-800
      text-white
    "
  >
    {{ formatTime(time) }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    time: String,
  },
  setup() {
    const formatTime = (time?: string) => {
      let formattedTime = time?.slice(0, 5) || '-';

      if (formattedTime == '00:00') {
        formattedTime = '-';
      }

      return formattedTime;
    };

    return {
      formatTime,
    };
  },
});
</script>
