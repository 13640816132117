<template>
  <div class="p-4 pt-0 animate-pulse">
    <div class="w-full h-96 flex items-center justify-center rounded-xl bg-gray-300">
      <MapIcon class="w-full h-5/6 text-gray-400" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MapIcon } from '@heroicons/vue/outline';

export default defineComponent({
  components: { MapIcon },
});
</script>
