<template>
  <main class="max-w-screen-2xl mx-auto px-4 pt-8 sm:pt-12 lg:px-12">
    <h1 class="mb-10 text-4xl font-heading md:mb-20 md:text-5xl">Contactez-nous</h1>

    <section class="grid grid-cols-2 gap-x-8">
      <ContactForm class="col-span-2 md:col-span-1" />
      <ContactInfo class="col-span-2 md:col-span-1" />
    </section>

    <ContactMap />
    <NewsLetter />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ContactForm from '@/components/contact/ContactForm.vue';
import ContactInfo from '@/components/contact/ContactInfo.vue';
import ContactMap from '@/components/contact/ContactMap.vue';
import NewsLetter from '@/components/home/NewsLetter.vue';

export default defineComponent({
  components: { ContactForm, ContactInfo, ContactMap, NewsLetter },
});
</script>
