<template>
  <div class="animate-pulse">
    <div class="w-full h-10 rounded-md bg-gray-300"></div>
    <div class="p-4 mt-4 h-56 grid grid-cols-2 grid-rows-2 gap-6">
      <div class="rounded-xl bg-gray-300"></div>
      <div class="rounded-xl bg-gray-300"></div>
      <div class="rounded-xl bg-gray-300"></div>
      <div class="rounded-xl bg-gray-300"></div>
    </div>
  </div>
</template>
