
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    time: String,
  },
  setup() {
    const formatTime = (time?: string) => {
      let formattedTime = time?.slice(0, 5) || '-';

      if (formattedTime == '00:00') {
        formattedTime = '-';
      }

      return formattedTime;
    };

    return {
      formatTime,
    };
  },
});
