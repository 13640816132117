
import { defineComponent } from 'vue';
import { ChatIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import ContactInfoSkeleton from '@/components/skeleton/ContactInfoSkeleton.vue';
import BusinessHours from './BusinessHours.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';

export default defineComponent({
  components: {
    ChatIcon,
    PhoneIcon,
    LocationMarkerIcon,
    BusinessHours,
    Skeleton,
    ContactInfoSkeleton,
  },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();

    return {
      restaurant,
      fetchingRestaurant,
    };
  },
});
