<template>
  <form class="space-y-5 md:max-w-xl" @submit.prevent="onSubmit">
    <DisplayError :errors="errors" class="text-center" />

    <div class="flex">
      <AppInput
        class="flex-1"
        id="first_name"
        label="Prénom"
        border="border border-gray-light
        rounded-bl-xl rounded-tl-xl"
        v-model="contactData.first_name"
        :errors="errors"
      />
      <AppInput
        class="flex-1"
        id="last_name"
        label="Nom"
        border="rounded-br-xl rounded-tr-xl"
        v-model="contactData.last_name"
        :errors="errors"
      />
    </div>

    <div class="flex space-x-4">
      <AppInput
        class="flex-1"
        id="email"
        type="email"
        label="E-mail"
        v-model="contactData.email"
        :errors="errors"
      />
      <AppInput
        class="flex-1"
        id="phone"
        label="Téléphone"
        v-model="contactData.phone"
        :errors="errors"
      />
    </div>

    <AppInput
      id="subject"
      label="Sujet"
      placeholder="A propos de quoi voudriez-vous nous contacter"
      v-model="contactData.subject"
      :errors="errors"
    />
    <AppInput
      type="textarea"
      id="message"
      label="Message"
      v-model="contactData.message"
      :errors="errors"
    />

    <AppButton
      type="submit"
      class="w-full bg-primary text-white hover:ring-red-200 focus:ring-red-200"
      :loading="captchaLoading || loading"
    >
      {{ captchaLoading ? 'Chargement du recapcha' : 'Envoyer' }}
      <ChevronRightIcon v-show="!captchaLoading && !loading" class="ml-2 w-5 h-5 text-white" />
    </AppButton>
  </form>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { useReCaptcha } from 'vue-recaptcha-v3';

import AppInput from '@/components/shared/AppInput.vue';
import AppButton from '@/components/shared/AppButton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';
import { useCreateContact } from '@/hooks/contact/use-contact';

export default defineComponent({
  components: { AppInput, AppButton, ChevronRightIcon, DisplayError },
  setup() {
    const captchaLoading = ref(true);
    const captcha = useReCaptcha();
    const { showToast } = useToast();
    const { user } = useUser();
    const { loading, errors, createContact } = useCreateContact();
    const contactData = ref({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      user: user.value?.id ?? undefined,
      captchaToken: '',
    });

    onMounted(async () => {
      try {
        captchaLoading.value = true;

        await captcha?.recaptchaLoaded();
        captcha?.instance.value?.showBadge();
      } catch {
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
      } finally {
        captchaLoading.value = false;
      }
    });

    onBeforeUnmount(() => {
      captcha?.instance.value?.hideBadge();
    });

    const onSubmit = async (e: Event) => {
      captchaLoading.value = true;
      const token = await captcha?.executeRecaptcha('submit');

      if (!token) {
        captchaLoading.value = false;
        showToast({ message: 'Échec du test reCAPTCHA', type: 'failure' });
        return;
      }

      contactData.value.captchaToken = token;

      if (!contactData.value.user) {
        delete contactData.value.user;
      }

      await createContact(contactData.value);

      captchaLoading.value = false;

      if (!errors.value.length) {
        showToast({ message: `Votre message a été envoyé! Nous vous répondrons bientôt` });
        (e.target as HTMLFormElement).reset();
      }
    };

    return {
      onSubmit,
      contactData,
      loading,
      errors,
      captchaLoading,
    };
  },
});
</script>
