<template>
  <section id="HeuresDouverture">
    <h5 class="mb-6 font-display text-white tracking-wide text-lg md:text-2xl">
      Heures de travail
    </h5>

    <skeleton :show="fetchingBusinessHours">
      <div v-if="businessHours.length">
        <div
          class="
            px-2
            py-1
            flex
            justify-between
            border
            rounded-md
            overflow-auto
            whitespace-nowrap
            space-x-1
            bg-gray-50
          "
          id="business-hours-container"
        >
          <button
            v-for="businessHour in businessHours"
            :key="businessHour.id"
            :class="[
              currentDay === businessHour.day ? 'bg-gray-800 text-white' : 'text-gray-600',
              'text-xs capitalize px-3 py-2 transition-colors rounded-md hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-800 sm:text-sm',
            ]"
            @click="currentDay = businessHour.day"
          >
            {{ businessHour.day }}
          </button>
        </div>

        <transition-group
          name="list"
          tag="div"
          appear
          class="mt-4 h-56 bg-gray-50 rounded-md overflow-hidden"
        >
          <div
            v-for="businessHour in businessHours"
            :key="businessHour.id"
            v-show="businessHour.day === currentDay"
            class="p-4 h-full text-2xl font-semibold grid grid-cols-1 gap-6"
            :class="{ 'grid-rows-2': hasBreak(businessHour.break_start) }"
          >
            <div class="flex items-center">
              <BusinessHourCard :time="businessHour.open_time" />
              <ArrowRightIcon class="mx-4 w-6 h-6 text-gray-900" />
              <BusinessHourCard
                v-if="hasBreak(businessHour.break_start)"
                :time="businessHour.break_start"
              />
              <BusinessHourCard v-else :time="businessHour.close_time" />
            </div>
            <div class="flex items-center" v-if="hasBreak(businessHour.break_start)">
              <BusinessHourCard :time="businessHour.break_end" />
              <ArrowRightIcon class="mx-4 w-6 h-6 text-gray-900" />
              <BusinessHourCard :time="businessHour.close_time" />
            </div>
          </div>
        </transition-group>
      </div>

      <div v-else class="px-2">
        <h4 class="text-gray-50 md:text-lg">Échec du chargement des heures d'ouverture</h4>
      </div>

      <template #fallback>
        <BusinessHoursSkeleton />
      </template>
    </skeleton>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { ArrowRightIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import BusinessHoursSkeleton from '@/components/skeleton/BusinessHoursSkeleton.vue';
import BusinessHourCard from './BusinessHourCard.vue';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  components: { ArrowRightIcon, BusinessHourCard, Skeleton, BusinessHoursSkeleton },
  setup() {
    const { businessHours, currentDay: day, fetchingBusinessHours } = useBusinessHours();
    const currentDay = ref(day.value);

    watch(day, (newDay) => (currentDay.value = newDay));

    const hasBreak = (break_start?: string) => {
      if (!break_start) return false;

      return !break_start.includes('00:00:00');
    };

    return {
      businessHours,
      fetchingBusinessHours,
      currentDay,
      hasBreak,
    };
  },
});
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.4s ease-in-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

#business-hours-container {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) rgb(208, 208, 208);
}

#business-hours-container::-webkit-scrollbar {
  width: 8px;
}

#business-hours-container::-webkit-scrollbar-track {
  background: rgb(208, 208, 208);
}

#business-hours-container::-webkit-scrollbar-thumb {
  background: #eecfc3;
}

#business-hours-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  border-radius: 10px;
}

#business-hours-container::-webkit-scrollbar-thumb:active {
  background: var(--primary);
  border-radius: 10px;
}
</style>
