
import { defineComponent } from 'vue';
import { ExternalLinkIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import MapSkeleton from '@/components/skeleton/MapSkeleton.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';

export default defineComponent({
  components: { ExternalLinkIcon, Skeleton, MapSkeleton },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();

    return {
      restaurant,
      fetchingRestaurant,
    };
  },
});
