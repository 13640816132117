import { Ref } from 'vue';

import { Contact } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';

export const useCreateContact: UseCreateContact = () => {
  const { errors, loading, request } = useRequest();

  const createContact = async (payload: Payload) => {
    return await request<Contact>({ url: `/contacts`, method: 'POST', data: payload });
  };

  return { errors, loading, createContact };
};

type UseCreateContact = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  createContact(payload: Payload): Promise<Contact | undefined>;
};

type Payload = Omit<Contact, 'id'> & {
  user?: number;
  captchaToken: string;
};
