<template>
  <section class="mt-24 md:mt-28" id="map">
    <h3 class="mb-8 text-4xl font-heading md:text-5xl">Trouvez-nous</h3>

    <skeleton :show="fetchingRestaurant">
      <div v-if="restaurant" class="h-96 w-full bg-red-300 relative md:mt-16">
        <div class="absolute flex right-2 top-4 space-x-4 md:space-x-6">
          <a
            class="p-3 py-2 rounded flex items-center space-x-2 bg-white text-gray-600"
            :href="`https://maps.google.com/maps?q=${restaurant.geo_latitude},${restaurant.geo_longitude}`"
            target="_blank"
          >
            <span>Google Maps</span>
            <ExternalLinkIcon class="w-5 h-5 text-gray-800" />
          </a>
          <a
            class="p-3 py-2 rounded flex items-center space-x-2 bg-white text-gray-600"
            :href="`https://maps.apple.com/?ll=${restaurant.geo_latitude},${restaurant.geo_longitude}`"
            target="_blank"
          >
            <span>Apple Maps</span>
            <ExternalLinkIcon class="w-5 h-5 text-gray-800" />
          </a>
        </div>
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          :src="`https://www.openstreetmap.org/export/embed.html?bbox=${restaurant.geo_longitude},${restaurant.geo_latitude}&amp;layer=mapnik&amp;marker=${restaurant.geo_latitude},${restaurant.geo_longitude}`"
        >
        </iframe>
      </div>

      <div v-else class="px-4">
        <h4 class="text-gray-700 md:text-lg">Échec du chargement de l'emplacement de la carte</h4>
      </div>

      <template #fallback>
        <MapSkeleton />
      </template>
    </skeleton>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ExternalLinkIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import MapSkeleton from '@/components/skeleton/MapSkeleton.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';

export default defineComponent({
  components: { ExternalLinkIcon, Skeleton, MapSkeleton },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();

    return {
      restaurant,
      fetchingRestaurant,
    };
  },
});
</script>
