<template>
  <div
    class="
      mt-10
      py-6
      px-4
      rounded-md
      space-y-10
      bg-primary bg-gradient-to-tl
      from-primary
      to-red-500
      md:mt-0
    "
  >
    <section>
      <h5 class="mb-6 font-display text-white tracking-wide text-lg md:text-2xl">
        Vous pouvez également nous trouver en utilisant ces
      </h5>
      <Skeleton :show="fetchingRestaurant">
        <div v-if="restaurant">
          <ul class="mt-8 space-y-4">
            <li class="flex items-center space-x-4">
              <ChatIcon class="h-6 w-6 text-gray-100" />
              <span class="text-gray-50 text-sm">{{ restaurant.email }}</span>
            </li>
            <li class="flex items-center space-x-4">
              <PhoneIcon class="h-6 w-6 text-gray-100" />
              <span class="text-gray-50 text-sm">{{ restaurant.phone }}</span>
            </li>
            <li class="flex items-center space-x-4">
              <a href="#map"><LocationMarkerIcon class="h-6 w-6 text-gray-100" /></a>
              <a href="#map" class="text-gray-50 text-sm">{{ restaurant.address }}</a>
            </li>
          </ul>
        </div>

        <div v-else class="px-2">
          <h4 class="text-gray-50 md:text-lg">Échec du chargement des informations de contact</h4>
        </div>

        <template #fallback>
          <ContactInfoSkeleton />
        </template>
      </Skeleton>
    </section>

    <BusinessHours />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ChatIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import ContactInfoSkeleton from '@/components/skeleton/ContactInfoSkeleton.vue';
import BusinessHours from './BusinessHours.vue';
import useRestaurant from '@/hooks/restaurant/use-restaurant';

export default defineComponent({
  components: {
    ChatIcon,
    PhoneIcon,
    LocationMarkerIcon,
    BusinessHours,
    Skeleton,
    ContactInfoSkeleton,
  },
  setup() {
    const { restaurant, fetchingRestaurant } = useRestaurant();

    return {
      restaurant,
      fetchingRestaurant,
    };
  },
});
</script>
