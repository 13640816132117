
import { defineComponent, ref, watch } from 'vue';
import { ArrowRightIcon } from '@heroicons/vue/outline';

import Skeleton from '@/components/skeleton/Skeleton.vue';
import BusinessHoursSkeleton from '@/components/skeleton/BusinessHoursSkeleton.vue';
import BusinessHourCard from './BusinessHourCard.vue';
import useBusinessHours from '@/hooks/business-hours/use-business-hours';

export default defineComponent({
  components: { ArrowRightIcon, BusinessHourCard, Skeleton, BusinessHoursSkeleton },
  setup() {
    const { businessHours, currentDay: day, fetchingBusinessHours } = useBusinessHours();
    const currentDay = ref(day.value);

    watch(day, (newDay) => (currentDay.value = newDay));

    const hasBreak = (break_start?: string) => {
      if (!break_start) return false;

      return !break_start.includes('00:00:00');
    };

    return {
      businessHours,
      fetchingBusinessHours,
      currentDay,
      hasBreak,
    };
  },
});
