
import { defineComponent } from 'vue';

import ContactForm from '@/components/contact/ContactForm.vue';
import ContactInfo from '@/components/contact/ContactInfo.vue';
import ContactMap from '@/components/contact/ContactMap.vue';
import NewsLetter from '@/components/home/NewsLetter.vue';

export default defineComponent({
  components: { ContactForm, ContactInfo, ContactMap, NewsLetter },
});
